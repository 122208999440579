<template>
  <div></div>
</template>

<script setup>
import { ElMessage } from "element-plus";
import { post, get } from "@/utils/index";
import { useRouter } from "vue-router";
const router = useRouter();


// 判断是否登录过期
const islogindel = () => {
  // 保存当前页面url到本地，以便登录后返回
  window.localStorage.setItem("loginurl",window.location.href);
  get("/member/checkOwnedItem").then((res) => {
    //
  });
};
islogindel();
</script>

<style scoped></style>
