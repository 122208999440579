<template>
  <div class="banner">
    <div class="banner-con">
      <div class="banner-txt">
        <div class="banner-txt-title">中图分类号查询</div>
        <div class="banner-txt-sbutitle">《中国图书馆分类法》（原称《中国图书馆图书分类法》）是我国建国后编制出版的一部具有代表性的大型综合性分类法，是当今国内图书馆使用最广泛的分类法体系，简称《中图法》。《中图法》初版于1975年，1999年出版了第四版。修订后的《中图法》第四版增加了类分资料的类目，并与类分图书的类目以“＋”标识进行了区分，因此正式改名为《中国图书馆分类法》，简称不变。《中图法》第四版全面补充新主题、扩充类目体系，使分类法跟上科学技术发展的步伐。同时规范类目，完善参照系统、注释系统，调整类目体系，增修复分表，明显加强类目的扩容性和分类的准确性。</div>
      </div>
      <div class="banner-search">
        <input type="text" class="banner-search-input" v-model="searchval" placeholder="请输入图书分类号或分类名查询">
        <div class="banner-search-btn" @click="clicksearchs">搜索</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
import { reactive, ref } from "vue";
import { ElMessage } from "element-plus";

// 搜索框
let searchval= ref('')
// 搜索
const clicksearchs=()=>{
  if (searchval.value.trim() == "") {
    ElMessage({
      type: "warning",
      message: "请输入分类号后搜索",
    });
    return;
  }
  router.push({
    path: "/flh/search",
    query: {
      keyword: encodeURIComponent(searchval.value),
    },
  });
}

</script>

<style scoped>
.banner {
  position: relative;
  width: 100%;
  height: 400px;
  background:url(/img/ztflh/banner.jpg) no-repeat center;
  background-size: 100% 100%;
}
.banner-con {
  width: 1200px;
  height: 100%;
  margin: auto;
  position: relative;
}
.banner-txt{
  color: #fff;
}
.banner-txt-title{
  text-align: center;
  font-size: 28px;
  padding-top: 55px;
  padding-bottom: 30px;
}
.banner-txt-sbutitle{
  text-align: justify;
  font-size: 16px;
  line-height: 26px;
}
.banner-search{
  width: 100%;
  height:60px;
  background: #fff;
  border-radius: 60px;
  margin-top: 45px;
}
.banner-search-input{
  float: left;
  outline: none;
  padding: 0 25px;
  margin: 5px 0 0 5px;
  border: 0;
  background: #fff;
  border-radius: 50px;
  height: 50px;
  width: 900px;
  font-size: 18px;
}
.banner-search-btn{
  float: right;
  margin: 5px 5px 0 0;
  width: 150px;
  height: 50px;
  background: #409EFF;
  border-radius: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}
.banner-search-btn:hover{
  opacity: .9;
}

</style>
