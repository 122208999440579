<template>
  <div class="banner">
    <div class="banner-con">
      <div class="banner-con-wz">
        <div class="banner-con-wz-title">优稿优投、急稿急投、定向约稿、精准匹配</div>
        <div class="banner-con-wz-subtitle">
          提交标题、摘要、关键词，评估筛选推荐期刊
        </div>
        <div class="banner-con-wz-btn">
          <div class="banner-con-wz-btns" @click="ljxk">立即选刊</div>
        </div>
      </div>
     
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
const ljxk = () => {
  router.push({
    path: "/xkzs/sub",
    query: {},
  });
};
</script>

<style scoped>
.banner {
  position: relative;
  width: 100%;
  height: 400px;
  background: linear-gradient(270deg, #55a3f8 0, #3476fe 100%);
}
.banner-con {
  width: 1200px;
  height: 100%;
  margin: auto;
  position: relative;
}
.banner-con-wz {
  color: #fff;
  width: 1200px;
  height: 100%;
}
.banner-con-wz-title {
  font-size: 46px;
  font-weight: bold;
  padding: 80px 0 0 0;
  text-align: center;
}
.banner-con-wz-subtitle {
  font-size: 20px;
  padding: 30px 0 50px 0;
  text-align: center;
}
.banner-con-wz-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
}
.banner-con-wz-btns {
  width: 400px;
  height: 60px;
  background: #fff;
  border: 1px solid #2f54eb;
  border-radius: 8px;
  color: #2f54eb;
  font-size: 24px;
  font-weight: 600;
  display: inline-block;
  text-align: center;
  line-height: 60px;
  cursor: pointer;
  transition: ease 0.4s all;
}
.banner-con-wz-btns:hover {
  background: #2f54eb;
  color: #fff;
  border: 1px solid #fff;
}
.banner-con-img {
  width: 340px;
  height: 350px;
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>
