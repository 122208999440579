<template>
  <div class="head">
    <div class="head-con">
      <div class="logos" @click="clicklogo">
        <el-image
          @click="clicklogo"
          style="width: 120px; height: 80px;float:left;"
          src="/img/common/logo.svg"
          fit="fill"
        />
        <div class="logos-txt">首站-论文投稿智能助手</div>
      </div>
      <div class="head-nav"></div>
    </div>
  </div>
</template>

<script setup>
import { Search } from '@element-plus/icons-vue'
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
// 回首页
const clicklogo = () => {
  router.push({
    path: "/",
    query: {},
  });
};
</script>

<style scoped>
.head {
  width: 100%;
  height: 80px;
  background: #fff;
  border-bottom: 1px solid #bbd6ff;
}
.head-con {
  width: 1200px;
  height: 100%;
  margin: auto;
}
.logos {
  width: 360px;
  height: 80px;
  float: left;
  cursor: pointer;
}
.logos-txt{
  width: 240px;
  height: 80px;
  float: left;
  font-weight: bold;
  font-size: 22px;
  line-height: 80px;
}
</style>
