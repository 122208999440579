import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

// history 关键字：createWebHistory
// hash 关键字：createWebHashHistory

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "首站-论文投稿智能助手",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description: "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  {
    path: "/xtjs",
    name: "/xtjs",
    component: () => import("@/views/index/xtjs.vue"),
    meta: {
      title:"系统介绍",
      content: {
        keywords: "",
        description:"",
      },
    },
  },
  {
    path: "/about",
    name: "/about",
    component: () => import("@/views/index/about.vue"),
    meta: {
      title:"关于我们",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:"",
      },
    },
  },
  {
    path: "/search",
    name: "/search",
    component: () => import("@/views/index/search.vue"),
    meta: {
      title:"搜索",
      content: {
        keywords: "",
        description:"",
      },
    },
  },
  // 查同导刊英文版
  {
    path: "/xkzsen/sub",
    name: "/xkzsen/sub",
    component: () => import("@/views/chatongdaokanEn/submit.vue"),
    meta: {
      title:
        "首站-论文投稿智能助手|智能选刊|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  {
    path: "/xkzsen/res",
    name: "/xkzsen/res",
    component: () => import("@/views/chatongdaokanEn/result.vue"),
    meta: {
      title:
        "首站-论文投稿智能助手|智能选刊|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },

  // 查同导刊 选刊助手
  {
    path: "/xkzs",
    name: "/xkzs",
    component: () => import("@/views/chatongdaokan/index.vue"),
    meta: {
      title:
        "论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  {
    path: "/xkzs/index",
    name: "/xkzs/index",
    component: () => import("@/views/chatongdaokan/indexnew.vue"),
    meta: {
      title:
        "论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  {
    path: "/xkzs/sub",
    name: "/xkzs/sub",
    component: () => import("@/views/chatongdaokan/submit.vue"),
    meta: {
      title:
        "首站-论文投稿智能助手|智能选刊|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  {
    path: "/xkzs/res",
    name: "/xkzs/res",
    component: () => import("@/views/chatongdaokan/result.vue"),
    meta: {
      title:
        "首站-论文投稿智能助手|智能选刊|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  {
    path: "/xkzs/all",
    name: "/xkzs/all",
    component: () => import("@/views/chatongdaokan/allqk.vue"),
    meta: {
      title:
        "首站-论文投稿智能助手|智能选刊|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  {
    path: "/xkzs/del",
    name: "/xkzs/del",
    component: () => import("@/views/chatongdaokan/detailQk.vue"),
    meta: {
      title: "",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  {
    path: "/xkzs/delwz",
    name: "/xkzs/delwz",
    component: () => import("@/views/chatongdaokan/detailWz.vue"),
    meta: {
      title: "",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  {
    path: "/xkzs/tgfx",
    name: "/xkzs/tgfx",
    component: () => import("@/views/chatongdaokan/tougaofaxian.vue"),
    meta: {
      title:
        "论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  {
    path: "/xkzs/report",
    name: "/xkzs/report",
    component: () => import("@/views/chatongdaokan/report.vue"),
    meta: {
      title:
        "论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  {
    path: "/xkzs/tgpg",
    name: "/xkzs/tgpg",
    component: () => import("@/views/chatongdaokan/tgpgsubmit.vue"),
    meta: {
      title:
        "论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  // 投稿评估期刊
  {
    path: "/xkzs/tgqk",
    name: "/xkzs/tgqk",
    component: () => import("@/views/chatongdaokan/tgpgQk.vue"),
    meta: {
      title:"",
      content: {
        keywords: "",
        description:"",
      },
    },
  },
  // 敏感词自查
  {
    path: "/mgc/sbu",
    name: "/mgc/sbu",
    component: () => import("@/views/mingancizicha/submit.vue"),
    meta: {
      title:
        "首站-论文投稿智能助手|敏感词自查|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  // 分词
  {
    path: "/fec/sbu",
    name: "/fec/sbu",
    component: () => import("@/views/fenci/submit.vue"),
    meta: {
      title:
        "首站-论文投稿智能助手|智能分词|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  {
    path: "/fec/sbu2",
    name: "/fec/sbu2",
    component: () => import("@/views/fenci/submit2.vue"),
    meta: {
      title:
        "首站-论文投稿智能助手|智能分词|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  // 论文优化
  {
    path: "/lwyh/sbu",
    name: "/lwyh/sbu",
    component: () => import("@/views/lunwenyouhua/submit.vue"),
    meta: {
      title:
        "首站-论文投稿智能助手|论文优化|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  {
    path: "/lwyh/tgfx",
    name: "/lwyh/tgfx",
    component: () => import("@/views/lunwenyouhua/tougaofaxian.vue"),
    meta: {
      title:
        "首站-论文投稿智能助手|论文优化|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  // 选题助手
  {
    path: "/xtzs/sbu",
    name: "/xtzs/sbu",
    component: () => import("@/views/xtzs/submit.vue"),
    meta: {
      title:
        "首站-论文投稿智能助手|论文拟题|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  {
    path: "/test",
    name: "/test",
    component: () => import("@/views/xtzs/testsubmit.vue"),
    meta: {
      title: "论文拟题",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  {
    path: "/xtzs/xc",
    name: "/xtzs/xc",
    component: () => import("@/views/xtzs/xuanci.vue"),
    meta: {
      title: "论文拟题",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  // 图片查重
  {
    path: "/tpcc/sbu",
    name: "/tpcc/sbu",
    component: () => import("@/views/tpcc/submit.vue"),
    meta: {
      title:
        "首站-论文投稿智能助手|图片查重|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  {
    path: "/tpcc/list",
    name: "/tpcc/list",
    component: () => import("@/views/tpcc/list.vue"),
    meta: {
      title: "图片查重",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  {
    path: "/tpcc/res",
    name: "/tpcc/res",
    component: () => import("@/views/tpcc/detail.vue"),
    meta: {
      title: "图片查重",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  // 智能排版
  {
    path: "/znpb/sbu",
    name: "/znpb/sbu",
    component: () => import("@/views/znpb/submit.vue"),
    meta: {
      title:
        "首站-论文投稿智能助手|智能排版|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  {
    path: "/znpb/list",
    name: "/znpb/list",
    component: () => import("@/views/znpb/list.vue"),
    meta: {
      title: "智能排版",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  // 中图分类号
  {
    path: "/hyzg/sbu",
    name: "/hyzg/sbu",
    component: () => import("@/views/hyzg/submit.vue"),
    meta: {
      title:
        "首站-论文投稿智能助手|中图分类号推荐|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  {
    path: "/hyzg/res",
    name: "/hyzg/res",
    component: () => import("@/views/hyzg/result.vue"),
    meta: {
      title: "中图分类号推荐",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  {
    path: "/hyzg/gc",
    name: "/hyzg/gc",
    component: () => import("@/views/hyzg/guocheng.vue"),
    meta: {
      title: "中图分类号推荐",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  // 文本查重
  {
    path: "/wbcc/sbu",
    name: "/wbcc/sbu",
    component: () => import("@/views/wbcc/submit.vue"),
    meta: {
      title: "文本查重",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  {
    path: "/wbcc/res",
    name: "/wbcc/res",
    component: () => import("@/views/wbcc/result.vue"),
    meta: {
      title: "文本查重",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  {
    path: "/wbcc/gc",
    name: "/wbcc/gc",
    component: () => import("@/views/wbcc/guocheng.vue"),
    meta: {
      title: "文本查重",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  // 参考文献生成器
  {
    path: "/sqjsz/sbu",
    name: "/sqjsz/sbu",
    component: () => import("@/views/sqjsz/submit.vue"),
    meta: {
      title:
        "首站-论文投稿智能助手|参考文献格式规范|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  {
    path: "/sqjsz/res",
    name: "/sqjsz/res",
    component: () => import("@/views/sqjsz/result.vue"),
    meta: {
      title: "参考文献格式规范",
      content: {
        keywords: "",
        description: "",
      },
    },
  },

  // 测试曾浩接口 输入机构输出期刊
  {
    path: "/qkzg/sbu",
    name: "/qkzg/sbu",
    component: () => import("@/views/qkzg/submit.vue"),
    meta: {
      title:
        "首站-论文投稿智能助手|单位历史发表期刊|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  // 简版投稿
  {
    path: "/jbtg/sbu",
    name: "/jbtg/sbu",
    component: () => import("@/views/jianbantougao/submit.vue"),
    meta: {
      title:
        "首站-论文投稿智能助手|简版投稿|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  // 中图分类号查询
  {
    path: "/flh",
    name: "/flh",
    component: () => import("@/views/zhongtufenleihaochaxun/index.vue"),
    meta: {
      title:
        "首站-论文投稿智能助手|中图分类号查询",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  {
    path: "/flh/list",
    name: "/flh/list",
    component: () => import("@/views/zhongtufenleihaochaxun/list.vue"),
    meta: {
      title:
        "首站-论文投稿智能助手|中图分类号查询",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  {
    path: "/flh/search",
    name: "/flh/search",
    component: () => import("@/views/zhongtufenleihaochaxun/search.vue"),
    meta: {
      title:
        "首站-论文投稿智能助手|中图分类号查询",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  // 作者
  {
    path: "/zuozhe",
    name: "/zuozhe",
    component: () => import("@/views/zuozhe/index.vue"),
    meta: {
      title:
        "首站-论文投稿智能助手|作者",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  // 登录
  {
    path: "/login",
    name: "/login",
    component: () => import("@/views/user/login.vue"),
    meta: {
      title:
        "首站-论文投稿智能助手|登录|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description:
          "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  // 注册
  {
    path: "/reg",
    name: "/reg",
    component: () => import("@/views/user/registered.vue"),
    meta: {
      title: "首站-论文投稿智能助手|注册|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  // 个人中心
  {
    path: "/pc",
    name: "/pc",
    component: () => import("@/views/user/personalCenter.vue"),
    meta: {
      title: "个人中心",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  // 期刊导航
  {
    path: "/nav",
    name: "/nav",
    component: () => import("@/views/qikandaohang/index.vue"),
    meta: {
      title: "首站-论文投稿智能助手|期刊导航|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description: "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  // 职称导航
  {
    path: "/xzzc",
    name: "/xzzc",
    component: () => import("@/views/zhichengdaohang/xzzc.vue"),
    meta: {
      title: "首站-论文投稿智能助手|职称导航|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description: "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  // 投稿到邮箱
  {
    path: "/tougao",
    name: "/tougao",
    component: () => import("@/views/tougao/index.vue"),
    meta: {
      title: "首站-论文投稿智能助手|投稿|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description: "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
  {
    path: "/xzzc/xsxq",
    name: "/xzzc/xsxq",
    component: () => import("@/views/zhichengdaohang/xsxq.vue"),
    meta: {
      title: "首站-论文投稿智能助手|职称导航|论文发表|论文智能投稿|期刊自助发表推荐|杂志社快速发表|查同导刊-域田数据官方网站",
      content: {
        keywords: "论文发表；自助发表；期刊推荐；智能投稿；自助投稿；查同",
        description: "查同导刊平台帮您发表的论文推荐发表建议供参考，输入论文标题和、摘要和关键词，就可匹配推荐期刊供发表论文选择。查同导刊（论文智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建议，并提供分析报告。",
      },
    },
  },
];

const router = createRouter({
  // 更改history模式
  // history: createWebHashHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes,
});

export default router;

