import axios from "axios";
import { ElMessage } from "element-plus";
import router from "@/router";

// 弹层登录
// import Confirm from "@/components/confirmlogin/index.js";
import DialogHandle from "@/components/confirmlogin/index.js";

// 设置基础路径
const baseURL = "https://titletools-api.yuanpingjia.cn";

// 创建一个 axios 实例
const requests = axios.create({
  baseURL: baseURL, // 所有的请求地址前缀部分
  timeout: 100 * 1000, // 请求超时时间毫秒
});

// 添加请求拦截器
requests.interceptors.request.use((config) => {
  // 添加token
  config.headers.Authorization =
    "Bearer " + window.localStorage.getItem("token") || "";
  // config.headers["Content-type"] = "application/json;charset=UTF-8";
  return config;
});

// 添加响应拦截器
requests.interceptors.response.use(
  (response: any) => {
    // typeof(response.data.code) == "undefined"  仅限于文档排版下载接口/wordFormat/download/使用
    if (
      response.data.code === 200 ||
      response.data.code === 0 ||
      typeof response.data.code == "undefined"
    ) {
      return response;
    } else if (response.data.code === 401) {
      // 清除token
      window.localStorage.removeItem("token");
      // 清除个人信息
      window.localStorage.removeItem("userInfo");

      ElMessage({
        message: response.data.message,
        type: "warning",
        duration: 1000,
        onClose: () => {
          // 调用弹窗登录
          // const dialog = DialogHandle({
          //   title: "操作确认",
          //   content: "<div>是否确定删除数据？</div>",
          //   onConfirm: () => {
          //     return new Promise(async (resolve) => {
          //       dialog.showLoading();
          //       ElMessage({
          //         message: "登录成功！",
          //         duration: 2000,
          //         onClose: () => {
          //           dialog.hideLoading();
          //           resolve('');
          //         },
          //       });

          //       // setTimeout(() => {
          //       //   dialog.hideLoading();
          //       //   resolve('');
          //       // }, 1500);
          //     });
          //   },
          // });

          // 跳转登录页
          router.push({
            path: "/login",
            query: {},
          });
        },
      });
    } else {
      ElMessage({
        message: response.data.msg || response.data.message,
        type: "error",
      });
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response.status === 401) {
      ElMessage({
        message: "请登录后再提交",
        type: "error",
        duration: 1000,
        onClose: () => {
          router.push({
            path: "/login",
            query: {},
          });
        },
      });
    }
    return Promise.reject(error);
  }
);

export { requests };
