<template>
  <div class="footer">
    <div class="footer-con">
      <div class="footer-left">
        <div class="footer-left-title">
          <div class="footer-left-title-list">关于投稿</div>
          <div class="footer-left-title-list">资源导航</div>
          <div class="footer-left-title-list">新手指南</div>
          <div class="footer-left-title-list">常见问题</div>
          <div class="footer-left-title-list">网站地图</div>
          <div class="footer-left-title-list">版权声明</div>
        </div>
        <div class="footer-left-title-yx">客服邮箱：kexuesuyang2022@163.com</div>
        <div class="footer-left-title-ban">
          <div class="footer-left-title-yx-list">渝公网安备：11010802029741号</div>
          <div class="footer-left-title-yx-list">ICP备案号：渝ICP备15016152号-6</div>
          <div class="footer-left-title-yx-list">某某科技 (重庆) 有限公司©版权所有</div>
        </div>
      </div>
      <div class="footer-right">
        <div class="footer-right-wx">
          <div class="footer-right-wx-title">微信公众号</div>
          <div class="footer-right-wx-ewm">
            <el-image style="width: 123px; height: 123px;" src="/img/common/kefu.png" fit="fill" />
          </div>
        </div>
        <div class="footer-right-wx">
          <div class="footer-right-wx-title">官方客服</div>
          <div class="footer-right-wx-ewm">
            <el-image style="width: 123px; height: 123px;" src="/img/common/kefu.png" fit="fill" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.footer{
  width: 100%;
  height: 250px;
  background: #fff;
  border-top: 3px solid #eee;
  box-sizing: border-box;
}
.footer-con{
  width: 1200px;
  height: 100%;
  margin: auto;
}
.footer-left{
  width: 900px;
  height: 100%;
  float: left;
}
.footer-right{
  width: 300px;
  height:100%;
  float: right;
  display: flex;
  justify-content: space-between;
}
.footer-left-title{
  width: 100%;
  display: flex;
  color: #444;
  font-size: 18px;
  font-weight: bold;
  margin-top: 60px;
}
.footer-left-title-list{
  margin-right: 40px;
  cursor: pointer;
}
.footer-left-title-list:hover{
  color: #5487e2;
}
.footer-left-title-yx{
  color: #666;
  font-size: 14px;
  margin: 20px 0;
}
.footer-left-title-ban{
  color: #666;
  font-size: 12px;
  display: flex;
}
.footer-left-title-yx-list{
  margin-right: 30px;
}
.footer-right-wx{
  width: 150px;
  height: 100%;
}
.footer-right-wx-title{
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 20px;
  margin-top: 30px;
}
.footer-right-wx-ewm{
  display: flex;
  justify-content: center;
}
</style>
