<template>
  <el-dialog v-model="visible" title="登录系统" width="800">
    <div class="login-con">
      <el-form
        :label-position="'top'"
        label-width="200px"
        :model="formLabelAlign"
        :rules="baseRules"
        ref="ruleFormRef"
      >
        <el-form-item label="用户名" required prop="userName">
          <el-input
            v-model="formLabelAlign.userName"
            placeholder="请输入用户名"
            @keyup.enter="onSubmit(ruleFormRef)"
          />
        </el-form-item>
        <el-form-item label="密码" required prop="password">
          <el-input
            type="password"
            v-model="formLabelAlign.password"
            placeholder="请输入密码"
            @keyup.enter="onSubmit(ruleFormRef)"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit(ruleFormRef)"
            >立即登录</el-button
          >
          <!-- <el-button type="warning" plain @click="clickwjmm"
                >忘记密码</el-button
              > -->
          <el-button @click="onzc">注册账号</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script setup>
// 纯js调用时必须，不然解析不出<el-dialog></el-dialog>、<el-button />
import { post, get } from "@/utils/index";
import {
  ElMessage,
  ElDialog,
  ElButton,
  ElForm,
  ElFormItem,
  ElInput,
} from "element-plus";
import {
  computed,
  defineProps,
  defineEmits,
  ref,
  reactive,
} from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
let _loading = ref(false);
// 获取父组件值
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(["update:modelValue", "refresh"]);
// computed绑定父组件值到v-model
const visible = computed({
  get() {
    return props.modelValue || _loading.value;
  },
  set(val) {
    emits("update:modelValue", val);
  },
});
// 显示
const showLoading = () => {
  _loading.value = true;
};
// 关闭
const hideLoading = () => {
  _loading.value = false;
};

// 登录相关代码  开始
let formLabelAlign = reactive({
  userName: "",
  password: "",
});
const ruleFormRef = ref();
const baseRules = {
  userName: [{ required: true, message: "用户名不能为空", trigger: "blur" }],
  password: [{ required: true, message: "密码不能为空", trigger: "blur" }],
};

const onSubmit = (formEl) => {
  // console.log(formEl);
  if (!formEl) return;
  formEl.validate((valid) => {
    // 通过验证规则 true
    if (valid) {
      post("/member/login", formLabelAlign).then((res) => {
        // 保存token
        window.localStorage.setItem("token", res.result.token);
        // 获取用户信息
        get("/member/getMemberInfo").then((result) => {
          window.localStorage.setItem(
            "userInfo",
            JSON.stringify(result.result)
          );
          ElMessage({
            message: "登录成功！",
            duration: 1000,
          });
        });
      });
    }
  });
};
// 登录
const onzc = () => {
  router.push({
    path: "/reg",
    query: {},
  });
};

// 登录相关代码  结束

// 暴露给纯js实例调用，可在实例中使用instance._instance.exposed查询到
defineExpose({ showLoading, hideLoading,handleClick });
</script>

<style scoped>
.login-con {
  width: 600px;
  height: 500px;
}
</style>
