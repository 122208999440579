<template>
  <div class="sm">
    <div class="custom-block">
      功能提示：{{props.smtips}}
    </div>

    <!-- <div class="sm-title">（点击查看原图）</div> -->
    <div class="sm-ul">
      <div class="sm-li" v-for="(item, key) in props.smdata" :key="key">
        <div>
          <el-image
            style="width: 98px; height: 98px; border: 1px solid #ccc"
            :src="item.url"
            :fit="fit"
            :preview-src-list="props.srcList"
            :infinite="false"
            :initial-index="key"
          />
          <div class="sm-li-tips">{{ item.tips }}</div>
        </div>
        <div class="sm-li-jiantou" v-if="smdata.length != key + 1">
          <svg
            t="1708658916848"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="9180"
            width="30"
            height="30"
          >
            <path
              d="M715.8 493.5L335 165.1c-14.2-12.2-35-1.2-35 18.5v656.8c0 19.7 20.8 30.7 35 18.5l380.8-328.4c10.9-9.4 10.9-27.6 0-37z"
              fill="#1296db"
              p-id="9181"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { ref, reactive, defineProps } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();

const props = defineProps({
  smdata: {
    type: Array, //类型字符串
    default: () => [],
  },
  srcList: {
    type: Array, //类型字符串
    default: () => [],
  },
  smtips:{
    type:String,
    default:'如图所示',
  },
});
</script>

<style scoped>
.sm {
  width: 100%;
  height: auto;
}
.custom-block {
  padding: 8px 16px;
  background-color: #ecf5ff;
  border-radius: 4px;
  border-left: 3px solid #409eff;
  margin: 15px 0 15px 0;
  font-size: 14px;
}
.sm-title {
  text-align: center;
}
.sm-ul {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.sm-li {
  width: 130px;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.sm-li-jiantou {
  width: 30px;
  height: 100px;
  display: flex;
  align-items: center;
}
.sm-li-tips {
  width: 100%;
  text-align: center;
  font-size: 12px;
}
</style>
