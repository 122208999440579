<template>
  <div class="footer">
    <div class="footer-con">
      <div class="footer-left">
        <div class="footer-left-title">
          <!-- <div class="footer-left-title-list" @click="clickhuidaoshouye">
            回到首页
          </div>
          <div class="footer-left-title-list" @click="clickgywm">关于我们</div>
          <div class="footer-left-title-list" @click="clickxtjs">系统介绍</div>
          <div class="footer-left-title-list">常见问题</div>
          <div class="footer-left-title-list">网站地图</div>
          <div class="footer-left-title-list">版权声明</div> -->
        </div>
        <div class="footer-left-title-yx">
          客服邮箱：957655803@qq.com
        </div>
        <div class="footer-left-title-ban">
          <div class="footer-left-title-yx-list">
            渝公网安备：50011902000454号
          </div>
          <div class="footer-left-title-yx-list">
            <a target="_blank" href="https://beian.miit.gov.cn/"  id="icp">ICP备案号：渝ICP备2023002303号</a>
          </div>
          <div class="footer-left-title-yx-list">
            重庆域田数据科技有限公司©版权所有
          </div>
        </div>
      </div>
      <div class="footer-right">
        <!-- <div class="footer-right-wx">
          <div class="footer-right-wx-title">移动端投稿</div>
          <div class="footer-right-wx-ewm">
            <el-image
              style="width: 123px; height: 123px"
              src="/img/common/kefu.png"
              fit="fill"
            />
          </div>
        </div> -->
        <div class="footer-right-wx">
          <div class="footer-right-wx-title">官方客服</div>
          <div class="footer-right-wx-ewm">
            <el-image
              style="width: 123px; height: 123px"
              src="/img/common/kefu2.png"
              fit="fill"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 百度统计代码
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?1e4f65f16370012add3ec1737f758343";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();
</script>
<script setup>
import { post } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const clickhuidaoshouye = () => {
  router.push({
    path: "/",
    query: {},
  });
};
const clickxtjs=()=>{
  router.push({
    path: "/xtjs",
    query: {},
  });
}
const clickgywm=()=>{
  router.push({
    path: "/about",
    query: {},
  });
}
</script>
<style scoped>
.footer {
  width: 100%;
  height: 250px;
  background: #374567;
  box-sizing: border-box;
  color: #fff;
}
.footer-con {
  width: 1200px;
  height: 100%;
  margin: auto;
}
.footer-left {
  width: 900px;
  height: 100%;
  float: left;
}
.footer-right {
  width: 300px;
  height: 100%;
  float: right;
  display: flex;
  justify-content: flex-end;
  /* justify-content: space-between; */
}
.footer-left-title {
  width: 100%;
  display: flex;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin-top: 60px;
}
.footer-left-title-list {
  margin-right: 40px;
  cursor: pointer;
}
.footer-left-title-list:hover {
  text-decoration: underline;
}
.footer-left-title-yx {
  color: #fff;
  font-size: 14px;
  margin: 20px 0;
}
.footer-left-title-ban {
  color: #fff;
  font-size: 12px;
  display: flex;
}
.footer-left-title-yx-list {
  margin-right: 30px;
}
.footer-right-wx {
  width: 150px;
  height: 100%;
}
.footer-right-wx-title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 20px;
  margin-top: 30px;
}
.footer-right-wx-ewm {
  display: flex;
  justify-content: center;
}
.footer-left-title-yx-list a:link,.footer-left-title-yx-list a:visited{
  color: #fff;
  text-decoration: none;
}
.footer-left-title-yx-list a:hover{
  text-decoration: underline;
}
</style>
