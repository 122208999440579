<template>
  <div class="head">
    <div class="head-con">
      <div class="logos" @click="clicklogo">
        <el-image
          @click="clicklogo"
          style="width: 120px; height: 80px; float: left"
          src="/img/common/logo.svg"
          fit="fill"
        />
        <div class="logos-txt">首站-论文投稿智能助手</div>
      </div>
      <div class="head-login" v-if="!headislogin">
        <div class="head-login-btns" @click="logins">登录</div>
        <div class="head-login-btns-zc" @click="registered">注册</div>
      </div>
      <div class="head-login" v-if="headislogin">
        <!--{{ headusername }}-->
        <div class="head-login-username" @click="clickgrzx">
          {{ headusername }}
        </div>
        <div class="head-login-btns-zc" @click="loginout">退出</div>
      </div>
      <div class="head-search">
        <el-input
          v-model="searchinput"
          placeholder="请输入期刊关键字"
          @keyup.enter="clicksearch"
        />
        <el-button type="success" :icon="Search" @click="clicksearch"
          >搜索</el-button
        >
      </div>
      <div class="head-nav">
        <div class="head-nav-list"><router-link to="/">首页</router-link></div>
        <div class="head-nav-list">
          <a href="//journal.yuanpingjia.com.cn/" target="_blank">期刊导航</a>
        </div>
        <div class="head-nav-list">
          <router-link to="/xzzc">职称导航</router-link>
        </div>
        <div class="head-nav-list">
          <a href="//ztflh.yuanpingjia.com.cn" target="_blank">中图分类号</a>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script setup>
import { Search } from "@element-plus/icons-vue";
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { ref, defineProps } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
let headislogin = ref(false);
// 用户名
let headusername = ref("");
// 搜索
let searchinput = ref("");

const props = defineProps({
  inputvalue: {
    type: String, //类型字符串
    default: "",
  },
});

// 更改登录状态
const editlogin = () => {
  if (window.localStorage.getItem("token")) {
    headislogin.value = true;
    headusername.value = JSON.parse(
      window.localStorage.getItem("userInfo")
    ).username;
  } else {
    headislogin.value = false;
  }
  if (props.inputvalue != "") {
    searchinput.value = props.inputvalue;
  }
};
editlogin();
// 注销
const loginout = () => {
  post("/member/logout").then((res) => {
    // 清除token
    window.localStorage.removeItem("token");
    // 清除个人信息
    window.localStorage.removeItem("userInfo");
    editlogin();
    ElMessage({
      message: "已退出系统！",
      duration: 1000,
      onClose: () => {
        router.push({
          path: "/",
          query: {},
        });
      },
    });
  });
};
// 个人中心
const clickgrzx = () => {
  // router.push({
  //   path: "/pc",
  //   query: {},
  // });
};
// 登录
const logins = () => {
  router.push({
    path: "/login",
    query: {},
  });
};
// 注册
const registered = () => {
  router.push({
    path: "/reg",
    query: {},
  });
};
// 回首页
const clicklogo = () => {
  router.push({
    path: "/",
    query: {},
  });
};
// 搜索
const clicksearch = () => {
  if (searchinput.value.trim() == "") {
    ElMessage({
      type: "warning",
      message: "请输入关键字",
    });
    return;
  }
  router.push({
    path: "/search",
    query: {
      keyword: encodeURIComponent(searchinput.value),
    },
  });
};
</script>

<style scoped>
.head {
  width: 100%;
  height: 80px;
  background: #fff;
}
.head-con {
  width: 1200px;
  height: 100%;
  margin: auto;
}
.head-login {
  width: 190px;
  height: 100%;
  float: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.head-search {
  width: 250px;
  height: 100%;
  margin-right: 10px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
}
.head-login-btns {
  width: 80px;
  height: 32px;
  font-size: 14px;
  background: #3860f4;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 15px;
}
.head-login-btns:hover {
  opacity: 0.9;
}
.head-login-username {
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: right;
  color: #3860f4;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.head-login-btns-zc {
  width: 80px;
  height: 32px;
  font-size: 14px;
  border: 1px solid #3860f4;
  background: #fff;
  box-sizing: border-box;
  color: #3860f4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}
.head-login-btns-zc:hover {
  background: #3860f4;
  color: #fff;
}
.head-nav {
  width: 350px;
  height: 100%;
  display: flex;
  align-items: center;
  float: right;
}
.head-nav-list {
  font-size: 18px;
  font-weight: bold;
  margin-right: 20px;
}
.head-nav-list a:link,
.head-nav-list a:visited {
  text-decoration: none;
}
.head-nav-list a:hover {
  color: #409bfc;
}
.logos {
  width: 360px;
  height: 80px;
  float: left;
  cursor: pointer;
}
.logos-txt {
  width: 240px;
  height: 80px;
  float: left;
  font-weight: bold;
  font-size: 22px;
  line-height: 80px;
}
</style>
