<template>
  <div class="main">
    <heads></heads>
    <banners></banners>
    <div class="content">
      <div class="con-block">
        <!-- <div class="con-block-title">
          <div class="con-block-titles">元评价产品</div>
          <div class="con-block-subtitle">PRODUCT</div>
        </div> -->
        <div class="con-block-ul" style="margin-top: 40px">
          <div class="con-block-li" @click="clickxtzs">
            <div class="con-block-li-icon">
              <el-image
                style="width: 55px; height: 45px"
                src="/img/index/icon1.png"
                :fit="'fill'"
              ></el-image>
            </div>
            <div class="con-block-li-title">论文拟题</div>
            <div class="con-block-li-icons">
              <el-icon color="#666">
                <Right />
              </el-icon>
            </div>
          </div>
          <div class="con-block-li" @click="clicklwyh">
            <div class="con-block-li-icon">
              <el-image
                style="width: 55px; height: 45px"
                src="/img/index/icon4-1.png"
                :fit="'fill'"
              ></el-image>
            </div>
            <div class="con-block-li-title">论文优化</div>
            <div class="con-block-li-icons">
              <el-icon color="#666">
                <Right />
              </el-icon>
            </div>
          </div>
          <div class="con-block-li" @click="clickxkzs">
            <div class="con-block-li-icon">
              <el-image
                style="width: 55px; height: 45px"
                src="/img/index/icon2.png"
                :fit="'fill'"
              ></el-image>
            </div>
            <div class="con-block-li-title">智能选刊</div>
            <div class="con-block-li-icons">
              <el-icon color="#666">
                <Right />
              </el-icon>
            </div>
          </div>
          <div class="con-block-li" @click="clickwbcc">
            <div class="con-block-li-icon">
              <el-image
                style="width: 55px; height: 45px"
                src="/img/index/icon5.png"
                :fit="'fill'"
              ></el-image>
            </div>
            <div class="con-block-li-title">维普查重</div>
            <div
              class="con-block-li-icons"
              style="color: #999; font-size: 14px"
            >
              暂未开放
            </div>
            <!-- <div class="con-block-li-sbutitle"></div> -->
            <!-- <div class="con-block-li-icons">
              <el-icon color="#666">
                <Right />
              </el-icon>
            </div> -->
          </div>
          <div class="con-block-li" @click="clicktpcc">
            <div class="con-block-li-icon">
              <el-image
                style="width: 55px; height: 45px"
                src="/img/index/icon6.png"
                :fit="'fill'"
              ></el-image>
            </div>
            <div class="con-block-li-title">图片查重</div>
            <div class="con-block-li-sbutitle"></div>
            <div class="con-block-li-icons">
              <el-icon color="#666">
                <Right />
              </el-icon>
            </div>
          </div>
        </div>

        <div class="con-block-ul">
          <div class="con-block-li" @click="clickwxscq">
            <div class="con-block-li-icon">
              <el-image
                style="width: 55px; height: 45px"
                src="/img/index/icon7-1.png"
                :fit="'fill'"
              ></el-image>
            </div>
            <div class="con-block-li-title">参考文献格式规范</div>
            <div class="con-block-li-sbutitle"></div>
            <div class="con-block-li-icons">
              <el-icon color="#666">
                <Right />
              </el-icon>
            </div>
          </div>
          <div class="con-block-li" @click="clickqkzg">
            <div class="con-block-li-icon">
              <el-image
                style="width: 55px; height: 45px"
                src="/img/index/icon3.png"
                :fit="'fill'"
              ></el-image>
            </div>
            <div class="con-block-li-title">单位历史发表期刊</div>
            <div class="con-block-li-icons">
              <el-icon color="#666">
                <Right />
              </el-icon>
            </div>
          </div>
          <div class="con-block-li" @click="clickhyzg">
            <div class="con-block-li-icon">
              <el-image
                style="width: 55px; height: 45px"
                src="/img/index/icon4.png"
                :fit="'fill'"
              ></el-image>
            </div>
            <div class="con-block-li-title">中图分类号推荐</div>
            <div class="con-block-li-icons">
              <el-icon color="#666">
                <Right />
              </el-icon>
            </div>
          </div>

          <div class="con-block-li" @click="clickznpb">
            <div class="con-block-li-icon">
              <el-image
                style="width: 55px; height: 45px"
                src="/img/index/icon7.png"
                :fit="'fill'"
              ></el-image>
            </div>
            <div class="con-block-li-title">智能排版</div>
            <div class="con-block-li-sbutitle"></div>
            <div class="con-block-li-icons">
              <el-icon color="#666">
                <Right />
              </el-icon>
            </div>
          </div>

          <div class="con-block-li" @click="clicksqjsz">
            <div class="con-block-li-icon">
              <el-image
                style="width: 55px; height: 45px"
                src="/img/index/icon8.png"
                :fit="'fill'"
              ></el-image>
            </div>
            <div class="con-block-li-title">敏感词自查</div>
            <div class="con-block-li-sbutitle"></div>
            <div class="con-block-li-icons">
              <el-icon color="#666">
                <Right />
              </el-icon>
            </div>
          </div>
        </div>

        <div class="con-block-ul">
          <div class="con-block-li" @click="clickznfc">
            <div class="con-block-li-icon">
              <el-image
                style="width: 55px; height: 45px"
                src="/img/index/icon11.png"
                :fit="'fill'"
              ></el-image>
            </div>
            <div class="con-block-li-title">智能分词</div>
            <div class="con-block-li-sbutitle"></div>
            <div class="con-block-li-icons">
              <el-icon color="#666">
                <Right />
              </el-icon>
            </div>
          </div>
          <div class="con-block-li" @click="clickjbtg">
            <div class="con-block-li-icon">
              <el-image
                style="width: 55px; height: 45px"
                src="/img/index/icon12.png"
                :fit="'fill'"
              ></el-image>
            </div>
            <div class="con-block-li-title">简版选刊</div>
            <div class="con-block-li-icons">
              <el-icon color="#666">
                <Right />
              </el-icon>
            </div>
          </div>
          <div class="con-block-li" @click="clickztflhcx">
            <div class="con-block-li-icon">
              <el-image
                style="width: 55px; height: 45px"
                src="/img/index/icon14.png"
                :fit="'fill'"
              ></el-image>
            </div>
            <div class="con-block-li-title">中图分类号查询</div>
            <div class="con-block-li-icons">
              <el-icon color="#666">
                <Right />
              </el-icon>
            </div>
          </div>
          <div class="con-block-li">
            <div class="con-block-li-icon">
              <el-image
                style="width: 55px; height: 45px"
                src="/img/index/icon13.png"
                :fit="'fill'"
              ></el-image>
            </div>
            <div class="con-block-li-title">更多工具</div>
            <div
              class="con-block-li-icons"
              style="color: #999; font-size: 14px"
            >
              敬请期待...
            </div>
          </div>
          <div class="con-block-li" style="visibility: hidden">
            <div class="con-block-li-icon">
              <el-image
                style="width: 55px; height: 45px"
                src="/img/index/icon3.png"
                :fit="'fill'"
              ></el-image>
            </div>
            <div class="con-block-li-title">隐藏</div>
            <div class="con-block-li-icons">
              <el-icon color="#666">
                <Right />
              </el-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="con-block">
        <div class="con-block-title">
          <div class="con-block-titles">推荐期刊</div>
          <div class="con-block-subtitle">RECOMMEND</div>
        </div>
        <div class="con-block-st">
          <el-carousel
            height="150px"
            indicator-position="outside"
            :interval="5000"
          >
            <el-carousel-item v-for="(i, k) in rmqklist" :key="k">
              <div class="con-block-st-qkul">
                <div
                  class="con-block-st-qklist"
                  v-for="(item, key) in i"
                  :key="key"
                  @click="clickrmqk(item.Id)"
                >
                  <el-image
                    style="width: 98px; height: 128px"
                    :src="`${proxy.$imgurl}/qikanfmpic/${item.pycode}.jpg`"
                    :fit="'fill'"
                    :title="item.Title"
                  ></el-image>
                  <div class="con-block-st-qklist-text">{{ item.Title }}</div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <div class="kefupiaochuang">
      <el-image
        style="width: 150px; height: 150px"
        src="/img/common/kefu2.png"
        :fit="'fill'"
        alt="客服咨询"
      ></el-image>
      <div class="kefupiaochuang-text">官方客服</div>
    </div>

    <footers></footers>
  </div>
</template>

<script setup>
import { get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
const { proxy } = getCurrentInstance();
const router = useRouter();

let rmqklist = reactive([]);

const tjyz = () => {
  document
    .querySelector('meta[name="360-site-verification"]')
    .setAttribute("content", "d2dd793e392d7f294518f0b24d4f9f12");
};
// tjyz();

// 获取热门期刊
const getrmqk = () => {
  get("/Journal/GetHotqkList").then((res) => {
    let rmqklisttempindex = 0;
    rmqklist[rmqklisttempindex] = [];
    res.data.forEach((e, i) => {
      rmqklist[rmqklisttempindex].push(e);
      if ((i + 1) % 10 == 0 && i < res.data.length - 1) {
        rmqklisttempindex++;
        rmqklist[rmqklisttempindex] = [];
      }
    });
  });
};
getrmqk();

// 跳转期刊
const clickrmqk = (id) => {
  // const url = router.resolve({
  //   path: "/xkzs/del",
  //   query: {
  //     id: id,
  //   },
  // });
  // window.open(url.href);
  window.open('//journal.yuanpingjia.com.cn/Journal/Details/'+id)
};

// 选刊助手
const clickxkzs = () => {
  router.push({
    path: "/xkzs/sub",
    query: {},
  });
};
// 选题助手
const clickxtzs = () => {
  router.push({
    path: "/xtzs/sbu",
    query: {},
  });
};
// 论文助手
const clicklwyh = () => {
  router.push({
    path: "/lwyh/sbu",
    query: {},
  });
};
// 文本查重
const clickwbcc = () => {
  ElMessage({
    type: "warning",
    message: "暂未开放",
  });
};
// 只能分词
const clickznfc = () => {
  router.push({
    path: "/fec/sbu",
    query: {},
  });
};

// 简版投稿
const clickjbtg=()=>{
  router.push({
    path: "/jbtg/sbu",
    query: {},
  });
}

// 图片查重
const clicktpcc = () => {
  router.push({
    path: "/tpcc/sbu",
    query: {},
  });
};

// 智能排版
const clickznpb = () => {
  router.push({
    path: "/znpb/sbu",
    query: {},
  });
};

// 中图分类号查询
const clickztflhcx=()=>{
  window.open('//ztflh.yuanpingjia.com.cn')
  // router.push({
  //   path: "/flh",
  //   query: {},
  // });
}

// 会议征稿
const clickhyzg = () => {
  router.push({
    path: "/hyzg/sbu",
    query: {},
  });
};
// 期刊征稿
const clickqkzg = () => {
  router.push({
    path: "/qkzg/sbu",
    query: {},
  });
};
// 参考文献生成
const clickwxscq = () => {
  router.push({
    path: "/sqjsz/sbu",
    query: {},
  });
};
// 神器建设中
const clicksqjsz = () => {
  router.push({
    path: "/mgc/sbu",
    query: {},
  });
};
</script>

<style scoped>
.main {
  position: relative;
  background: #fbfcff;
}
.content {
  width: 1200px;
  height: auto;
  padding: 0;
  margin: auto;
}
.con-block-title {
  display: flex;
  padding: 20px 0 0 0;
}
.con-block-titles {
  font-size: 24px;
  font-weight: bold;
}
.con-block-subtitle {
  color: #98a6c6;
  margin-top: 10px;
  margin-left: 10px;
}
.con-block-ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}
.con-block-li {
  position: relative;
  width: 230px;
  height: 130px;
  padding: 25px 20px;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #f0f0f0;
  cursor: pointer;
}
.con-block-li:hover {
  border: 1px solid #3860f4;
}
.con-block-li-title {
  font-size: 18px;
  font-weight: bold;
  color: #374567;
  padding: 10px 0 20px 0;
}
.con-block-li-sbutitle {
  color: #7a8ba6;
  line-height: 18px;
  text-align: justify;
  font-size: 14px;
}
.con-block-li-sbutitle > p {
  padding: 0;
  margin: 0;
}
.con-block-li-icons {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.con-block-st {
  padding: 20px 0;
}
.con-block-st-qkul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.con-block-st-qklist {
  width: 98px;
  height: 128px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.con-block-st-qklist:hover {
  border: 1px solid #3860f4;
}
.con-block-st-hyul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.con-block-st-hyli {
  width: 280px;
  height: 250px;
  cursor: pointer;
}
.con-block-st-hyli-wz {
  width: 100%;
  text-align: center;
  padding-top: 10px;
  font-weight: bold;
}
.con-block-st-hyli-wz:hover {
  color: #3860f4;
}
.con-block-st-qklist-text {
  text-align: center;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.kefupiaochuang {
  position: fixed;
  right: 10px;
  top: 150px;
  box-shadow: 0 0 10px #eee;
  width: 150px;
  height: 170px;
  padding: 10px;
  background: #fff;
}
.kefupiaochuang-text {
  width: 100%;
  height: 20px;
  text-align: center;
}
</style>
