<template>
  <div class="head">
    <div class="head-con">
      <el-image
        @click="clicklogo"
        style="width: 300px; height: 65px; float: left;cursor: pointer;"
        src="/img/common/logo.png"
        fit="fill"
      />
      <div class="head-nav"></div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
const clicklogo = () => {
  router.push({
    path: "/xkzs",
    query: {},
  });
};
</script>

<style scoped>
.head {
  width: 100%;
  height: 65px;
  background: #fff;
}
.head-con {
  width: 1200px;
  height: 100%;
  margin: auto;
}
</style>
