import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 使用vue-meta-info插件，改变link、title、meta等
import MetaInfo from "vue-meta-info";
// element
import ElementPlus from "element-plus";
import * as ElIcon from "@element-plus/icons-vue"; //icon
import "element-plus/dist/index.css";
import locale from "element-plus/lib/locale/lang/zh-cn"; //中文
// 顶部底部等公共部分
import heads from "./components/common/head.vue";
import headlogin from "./components/common/headLogin.vue";
import navs from "./components/common/nav.vue";
import banners from "./components/common/banner.vue";
import footers from "./components/common/footer.vue";
// 查同导刊智能选刊公共部分
import ctdkheads from "./components/ctdk/head.vue";
import ctdkbanners from "./components/ctdk/banner.vue";
import ctdkfooters from "./components/ctdk/footer.vue";
// 会议征稿公共部分
import hyzgbanners from "./components/hyzg/banner.vue";
// 中图分类号查询banner
import ztflhbanners from "./components/ztflh/banner.vue";
// 是否登录判断
import islogin from "./components/common/islogin.vue";
// 说明模块
import shuoming from "./components/common/shuoming.vue";
// 登录
import tclogin from "./components/common/login.vue";

//前置守卫 动态设置title 关键字
router.beforeEach((to, from, next) => {
  const tmcontent: any = to.meta.content;

  if (to.matched.length === 0) {
    // 如果未匹配到路由，则重定向到首页
    from.name ? next({ name: from.name }) : next("/");
  }
  if (tmcontent) {
    const head = document.getElementsByTagName("head");
    const meta = document.createElement("meta");
    const keywordss = document.querySelector('meta[name="keywords"]');
    if (keywordss) {
      keywordss.setAttribute("content", tmcontent.keywords);
    }
    const descriptionss = document.querySelector('meta[name="description"]');
    if (descriptionss) {
      descriptionss.setAttribute("content", tmcontent.description);
    }

    meta.content = tmcontent;
    head[0].appendChild(meta);
  }
  const tmtitle: any = to.meta.title;
  if (tmtitle) {
    document.title = tmtitle;
  }
  next();
});

// echarts
import * as echarts from "echarts";
const app = createApp(App);
app.config.globalProperties.$echarts = echarts;
// 图片ip地址
app.config.globalProperties.$imgurl =
  "https://yuanpingjia-com-cn-1304761361.cos.ap-chongqing.myqcloud.com";

Object.keys(ElIcon).forEach((key) => {
  app.component(key, ElIcon[key]);
});

app
  .component("heads", heads)
  .component("headlogin", headlogin)
  .component("islogin", islogin)
  .component("navs", navs)
  .component("banners", banners)
  .component("footers", footers)
  .component("ctdkheads", ctdkheads)
  .component("ctdkbanners", ctdkbanners)
  .component("ctdkfooters", ctdkfooters)
  .component("hyzgbanners", hyzgbanners)
  .component("ztflhbanners", ztflhbanners)
  .component("shuoming", shuoming)
  .component("tclogin", tclogin)
  .use(store)
  .use(router)
  .use(MetaInfo)
  // .use(ElementPlus)
  .use(ElementPlus, { locale })
  .mount("#app");
